export function getLocalStorageItem(key) {
  const item = localStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
  return undefined
}

export function setLocalStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function removeLocalStorageItem(key) {
  localStorage.removeItem(key)
}

export function resetLocalStorage() {
  localStorage.clear()
}
