import { Suspense, useEffect } from 'react'
import { ThemeProvider, Typography, Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate, useRoutes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import routes from './routes'
import { theme } from './assets/styles/theme/theme'
import { store } from './redux/store'
import useAuth from './hooks/useAuth'
import AuthService from './services/auth.service'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from './services/local-storage'
import LocalStorageKeys from './utils/local-storage-keys'
import './app.sass'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20rem',
}

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '175%',
}))

const ErrorFallback = ({ error }) => {
  return (
    <Box role='alert' sx={style}>
      <Box
        sx={{
          padding: '1rem',
          borderRadius: '8px',
          background: '#fafafa',
          color: '#252525',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '1rem',
          border: '1px solid #252525',
        }}
      >
        <Typography>Something went wrong</Typography>
        <Divider
          flexItem
          orientation='horizontal'
          sx={{ bgcolor: '#252525' }}
        />
        <StyledTypography color='error'>{error}</StyledTypography>
      </Box>
    </Box>
  )
}

export const Loading = () => {
  return (
    <div className='spinner-container'>
      <div className='loading-spinner'></div>
    </div>
  )
}

function App() {
  const content = useRoutes(routes)
  const { user, accessToken, signOut, isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (accessToken && accessToken.token) {
      setLocalStorageItem(LocalStorageKeys.authToken, accessToken.token)
      fetchAccountDetails()
    }
  }, [])

  function fetchAccountDetails() {
    const savedUser = getLocalStorageItem(LocalStorageKeys.userDetails)
    if (savedUser) {
      if (!savedUser.cage_ncage_number) {
        navigate('user-on-boarding')
      }
    } else {
      if (user) {
        const auth0UserID = user.id.replace('google-oauth2|', '')
        AuthService.fetchUserAccountDetails(auth0UserID).then(
          (fetchedUserDetails) => {
            if (fetchedUserDetails) {
              setLocalStorageItem(
                LocalStorageKeys.userDetails,
                fetchedUserDetails
              )
              if (!fetchedUserDetails.cage_ncage_number) {
                navigate('user-on-boarding')
              }
            }
          }
        )
      }
    }
  }

  return (
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<Loading />}>
          <div
            style={{ position: 'absolute', top: 0, left: '50%' }}
            id={'confettiContainer'}
          ></div>
          <ThemeProvider theme={theme}>{content}</ThemeProvider>
        </Suspense>
      </ErrorBoundary>
    </Provider>
  )
}

export default App
