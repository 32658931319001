import { Formik } from 'formik'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import LoginButton from './LoginButton'
import SignupButton from './SignupButton'
import { Box } from '@mui/material'

const Auth = () => {
  const { signOut } = useAuth()
  const navigate = useNavigate()
  const { signIn } = useAuth()

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: false
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn()
          // navigate("/dashboard");
        } catch (error) {
          const message = error.message || 'Something went wrong'
          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {process.env.APPSETTING_REACT_APP_ENV}
            <LoginButton disabled={isSubmitting} />
            <SignupButton disabled={isSubmitting} />
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default Auth
