import * as React from 'react'
import {styled, useTheme} from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import "./app-layout.sass"
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {ReactComponent as RFILogo} from '../../assets/icons/RFI-Alerts.svg'
import {
    Avatar,
    Box,
    LinearProgress,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Toolbar,
    List,
    CssBaseline,
    Typography,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Badge,
} from '@mui/material'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import useAuth from '../../hooks/useAuth'
import Auth from '../auth/Auth'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import BookmarksTwoToneIcon from '@mui/icons-material/BookmarksTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import './app-layout.sass'

const drawerWidth = 240
const iconStyle = {
    fontSize: "28px",
    color: "#454545"
}
const drawerMenu = [
    // {
    //     text: 'Dashboard',
    //     icon: <DashboardTwoToneIcon sx={iconStyle}/>,
    //     link: '/dashboard',
    // },
    {
        text: 'Search',
        icon: <SearchTwoToneIcon sx={iconStyle}/>,
        link: '/search',
    },
    {
        text: 'My Alerts',
        icon: <BoltOutlinedIcon sx={iconStyle}/>,
        link: '/alerts',
    },
    {
        text: 'Saved',
        icon: <BookmarksTwoToneIcon sx={iconStyle}/>,
        link: '/saved',
    },
    // {
    //     text: 'Notifications',
    //     icon: <NotificationsTwoToneIcon sx={iconStyle}/>,
    //     link: '/notifications',
    // },
    // {
    //     text: 'Settings',
    //     icon: <SettingsOutlinedIcon sx={iconStyle}/>,
    //     link: '/settings',
    // },
]

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'backgroundColor.primary',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const MenuEntryItemStyle = styled(Box)(({theme}) => ({
    display: 'flex',
    width: "56px",
    height: "56px",
    alignItems: 'center',
    justifyContent: 'center',
    background: "white",
    borderRadius: "50%",
    border: "1px solid #F0F0F0",
    boxShadow: "0px 3px 34px rgba(200, 200, 200, 0.25)"
}))

const AppLayout = ({children}) => {
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)
    const appState = useSelector((state) => state.appState)
    const location = useLocation()
    const {user, accessToken, signOut, isAuthenticated} = useAuth()
    const [anchorMenu, setAnchorMenu] = React.useState(null)
    const navigate = useNavigate()
    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget)
    }

    const closeMenu = async () => {
        setAnchorMenu(null)
    }

    const handleSignOut = async () => {
        await signOut()
        navigate('/')
    }

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{display: 'flex', height: "100%"}}>
            <CssBaseline/>

            {/* App Bar */}

            <AppBar
                position='fixed'
                color='backgroundColor'
                elevation={0}
                open={open}
            >
                <Toolbar sx={{padding: "10px 28px !important"}}>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        edge='start'
                        sx={{
                            marginRight: 4,
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon sx={{fontSize: "36px"}}/>
                    </IconButton>

                    <Box
                        sx={{display: 'flex', flexGrow: 1, alignItems: 'center', gap: 2}}
                    >
                        <Link to='/' style={{textDecoration: 'none', lineHeight: 0}}>
                            <RFILogo className='brand-logo'/>
                        </Link>
                    </Box>
                    <Stack
                        direction={'row'}
                        justifyContent='flex-end'
                        alignItems={'center'}
                        gap='10px'
                    >
                        {!!user && (
                            <Typography variant='body2'>
                                {user.displayName ? user.displayName : user.email}
                            </Typography>
                        )}
                        {isAuthenticated ? (
                            <Box>
                                <Tooltip title='Account'>
                                    <IconButton sx={{p: 0}} onClick={toggleMenu}>
                                        {!!user && <Avatar alt={user?.email} src={user?.avatar}/>}
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id='menu-appbar'
                                    anchorEl={anchorMenu}
                                    open={Boolean(anchorMenu)}
                                    onClose={closeMenu}
                                >
                                    <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                                </Menu>
                            </Box>
                        ) : (
                            <Auth/>
                        )}
                    </Stack>
                </Toolbar>

                {appState.loading ? <LinearProgress/> : null}
            </AppBar>

            {/* Drawer */}

            <Drawer
                variant='permanent'
                sx={{
                    '& .MuiDrawer-paper': {
                        borderWidth: 0,
                        backgroundColor: 'backgroundColor.main',
                    },
                }}
                open={open}
            >
                <DrawerHeader>
                    <IconButton
                        onClick={handleDrawerClose}
                        edge='start'
                        // size='large'
                        color='inherit'
                        aria-label='menu open'
                    >
                        <MenuOpenIcon sx={{fontSize: "36px"}}/>
                    </IconButton>
                </DrawerHeader>
                <List>
                    {drawerMenu.map((menu) => (
                        <ListItem
                            key={menu.text}
                            disablePadding
                            sx={{display: 'block', mb: '25px', '&hover': {color: '#2768d7'},}}
                            component={Link}
                            to={menu.link}
                            className={`menu-item ${location.pathname === menu.link ? '-active' : ''}`}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    padding: open ? "0 20px" : 0,
                                }}
                            >
                                <MenuEntryItemStyle>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {menu.text === 'My Alerts' ? (
                                            <Badge
                                                badgeContent={4}
                                                color='badgeColor'
                                                sx={{color: '#fff'}}
                                            >
                                                {menu.icon}
                                            </Badge>
                                        ) : (
                                            menu.icon
                                        )}
                                    </ListItemIcon>
                                </MenuEntryItemStyle>

                                {
                                    open && (<ListItemText
                                        disableTypography={true}
                                        primary={menu.text}
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            fontSize: open ? '18px' : '0',
                                            fontWeight: '500',
                                            margin: open ? '0 0 0 14px' : '0',
                                        }}
                                    />)
                                }

                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box
                component='main'
                sx={{
                    width: '100%',
                    height: 'fit-content',
                    backgroundColor: 'backgroundColor.main',
                }}
            >
                <DrawerHeader/>
                {children}
            </Box>
        </Box>
    )
}

export default AppLayout
