import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  aiSearch: true,
  search: {
    searchTerm: '',
    filters: [] // TODO: Refine the serach filters state
  },
  searchTerm: ''
}

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      return {
        ...state,
        search: {...state.search, ...action.payload}
      }
    },

    setSearchTerm: (state, action) => {
      return {
        ...state,
        searchTerm: action.payload
      }
    },
    // TODO: Remove this reducer for AI Search
    setAiSearch: (state, action) => {
      return {
        ...state,
        aiSearch: action.payload
      }
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    }
  }
})

const { actions: appStateActions, reducer: appStateReducer } = appStateSlice

export { appStateActions, appStateReducer }
