import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#252525',
    },
    secondary: {
      main: '#8871f2',
    },
    error: {
      main: '#ff8989',
    },
    success: {
      main: '#62a87c',
    },
    borderColor: {
      main: '#D0D5DD',
    },
    backgroundColor: {
      main: '#fafafa',
    },
    badgeColor: {
      main: '#e54b4b',
    },
    btnColor: {
      main: '#2768d7',
    },
    timerWarningColor: {
      main: '#FFBF46',
    },
    timerExpiredColor: {
      main: '#BC2C1A',
    },
    timerColor: {
      main: '#32A287',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 600,
    },
    fontFamily: 'Poppins',
  },
})
