import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth()

  if (!isAuthenticated) {
    return <Navigate to='/' />
  }

  return <>{children}</>
}

export function RoleGuard({ children }) {
  const { user } = useAuth()
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return <Navigate to='/' />
  }
}

export default AuthGuard
