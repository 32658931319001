// import styled from "@emotion/styled";
import { Button, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/styles'

const SignupButton = ({ disabled }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const buttonProps = {
    size: isSmallScreen ? 'small' : 'medium'
  }
  return (
    <Button
      type='submit'
      variant='contained'
      color='primary'
      disableElevation
      disabled={disabled}
      {...buttonProps}
      sx={{
        borderRadius: '6px',
        whiteSpace: 'nowrap'
      }}
    >
      Sign Up
    </Button>
  )
}

export default SignupButton
