import { Button, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

const LoginButton = ({ disabled }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const buttonProps = {
    size: isSmallScreen ? 'small' : 'medium'
  }

  return (
    <Button
      type='submit'
      variant='text'
      color='primary'
      disableElevation
      {...buttonProps}
      disabled={disabled}
      sx={{
        whiteSpace: 'nowrap'
      }}
    >
      Log In
    </Button>
  )
}

export default LoginButton
