import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  onBoardingSteps: []
}

export const userOnBoardingSlice = createSlice({
  name: 'userOnBoardingState',
  initialState,
  reducers: {
    setOnBoarding: (state, action) => {
      return {
        ...state,
        onBoardingSteps: action.payload
      }
    }
  }
})

const {
  actions: userOnBoardingStateActions,
  reducer: userOnBoardingStateReducer
} = userOnBoardingSlice

export { userOnBoardingStateActions, userOnBoardingStateReducer }
