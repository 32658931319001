import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './assets/styles/style.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './contexts/AuthContext'
import {SnackbarProvider} from "notistack";
import {theme} from "./assets/styles/theme/theme";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
          <SnackbarProvider
              maxSnack={3}
              classes={{
                  variantSuccess: theme.palette.success.main,
                  variantError: theme.palette.error.main,
                  variantWarning: theme.palette.warning.main,
                  variantInfo: theme.palette.info.main,
              }}
          >
        <App />
          </SnackbarProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log search (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
