import { configureStore } from '@reduxjs/toolkit'
import { appStateReducer } from './slices/appStateSlice'
import { searchResultsStateReducer } from './slices/searchResultsSlice'
import { userOnBoardingStateReducer } from './slices/userOnBoardingSlice'
import {alertsStateReducer} from "./slices/alertsSlice";

export const store = configureStore({
  reducer: {
    appState: appStateReducer,
    resultsState: searchResultsStateReducer,
    userOnBoardingState: userOnBoardingStateReducer,
    alertsState: alertsStateReducer,
  }
})
