import axios from 'axios'
import LocalStorageKeys from '../utils/local-storage-keys'
import { getLocalStorageItem, setLocalStorageItem } from './local-storage'

/**
 * Service to handle the auth state and auth related assets (tokens e.t.c)
 * Tokens and user details are store in the local storage.
 * */
async function fetchUserAccountDetails(auth0UID) {
  const authToken = getLocalStorageItem(LocalStorageKeys.authToken)
  if (authToken) {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    }

    return await axios
      .get(process.env.REACT_APP_BASE_URL + `users/auth0/${auth0UID}/`, config)
      .then((res) => {
        if (res && res.data) {
          const user = res.data
          if (user) {
            setLocalStorageItem(LocalStorageKeys.userDetails, user)
          } else {
            return undefined
          }
          return user
        }
      })
  }
}

const AuthService = { fetchUserAccountDetails }

export default AuthService
