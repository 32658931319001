import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  results: {
    result: { contracts: [], error: '', message: '' },
    count: 0,
  },
  start_row: 0,
  hit: 10,
  keyword: '',
  department: '',
  state: '',
  setAside: '',
  contracttype: '',
}

export const searchResultsSlice = createSlice({
  name: 'searchResultsState',
  initialState,
  reducers: {
    setResults: (state, action) => {
      return {
        ...state,
        results: { ...action.payload },
      }
    },
    resetResults: (state) => {
      return {...initialState}
    },
    setPagination: (state, action) => {
        const {start_row, hit} = action.payload;
        return {
            ...state,
            start_row: start_row || state.start_row,
            hit: hit || state.hit
        }
    },
  },
})

const {
  actions: searchResultsStateActions,
  reducer: searchResultsStateReducer,
} = searchResultsSlice

export { searchResultsStateActions, searchResultsStateReducer }
