import {lazy} from 'react'
import AuthGuard from './guards/AuthGuard'
import AppLayout from './components/layout/AppLayout'
import OnBoardingLayout from './components/layout/OnBoardingLayout'

const Home = lazy(() => import('./pages/home/Home'))
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'))
const Search = lazy(() => import('./pages/search/Search'))
const Alerts = lazy(() => import('./pages/alerts/Alerts'))
const UserOnBoarding = lazy(() =>
    import('./pages/user-on-boarding/user-on-boarding')
)

const routes = [
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: 'search',
        element: (
            <AppLayout>
                <Search/>
            </AppLayout>
        ),
    },
    {
        path: 'user-on-boarding',
        element: <UserOnBoarding/>,
    },
    {
        path: 'dashboard',
        element: (
            <AppLayout>
                <Dashboard/>
            </AppLayout>
        ),
    },
    {
        path: 'alerts',
        element: (
            <AppLayout>
                <Alerts/>
            </AppLayout>
        )
    }
]

export default routes
