import { createContext, useEffect, useReducer } from 'react'
import { Auth0Client } from '@auth0/auth0-spa-js'

import { auth0Config } from '../config'
import { useNavigate } from 'react-router-dom'
import {
  resetLocalStorage,
  setLocalStorageItem,
} from '../services/local-storage'
import AuthService from '../services/auth.service'
import LocalStorageKeys from '../utils/local-storage-keys'

const INITIALIZE = 'INITIALIZE'
const SIGN_IN = 'SIGN_IN'
const SIGN_OUT = 'SIGN_OUT'

let auth0Client = null

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  accessToken: null,
  user: null,
}

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, accessToken, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      accessToken,
      isInitialized: true,
      user,
    }
  }
  if (action.type === SIGN_IN) {
    const { user, accessToken } = action.payload
    return { ...state, isAuthenticated: true, accessToken, user }
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      accessToken: null,
      user: null,
    }
  }
  return state
}

const AuthContext = createContext(null)

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          client_id: auth0Config.clientId || '',
          domain: auth0Config.domain || '',
          redirect_uri: `${window.location.origin}/`,
          audience: auth0Config.audience,
        })

        await auth0Client.checkSession()

        const isAuthenticated = await auth0Client.isAuthenticated()

        if (isAuthenticated) {
          const user = await auth0Client.getUser()

          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user: user || null },
          })
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user: null },
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        })
      }
    }

    initialize()
  }, [])

  const signIn = async () => {
    await auth0Client?.loginWithPopup()
    const isAuthenticated = await auth0Client?.isAuthenticated()

    if (isAuthenticated) {
      const user = await auth0Client?.getUser()
      const accessToken = await auth0Client?.getTokenSilently()
      dispatch({
        type: SIGN_IN,
        payload: {
          user: user || null,
          accessToken: accessToken || null,
        },
      })

      setLocalStorageItem(LocalStorageKeys.authToken, accessToken)

      if (user) {
        const auth0UserID = user.sub.replace('google-oauth2|', '')

        AuthService.fetchUserAccountDetails(auth0UserID).then(
          (fetchedUserDetails) => {
            if (fetchedUserDetails) {
              setLocalStorageItem(
                LocalStorageKeys.userDetails,
                fetchedUserDetails
              )
              if (!fetchedUserDetails.cage_ncage_number) {
                navigate('user-on-boarding')
              }
            }
          }
        )
      }
    }
  }

  const signOut = () => {
    auth0Client?.logout({ returnTo: window.location.origin })
    resetLocalStorage()
    dispatch({ type: SIGN_OUT })
  }
  // const resetPassword = (email) => { };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        user: {
          id: state?.user?.sub,
          avatar: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.displayName,
          role: state?.user?.role,
        },
        signIn,
        signOut,
        accessToken: { token: state?.accessToken },
        // resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
