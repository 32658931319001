import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    alerts: null,
}

export const alertsSlice = createSlice({
    name: 'alertsSlice',
    initialState,
    reducers: {
        setAlerts: (state, action) => {
            return {
                ...state,
                alerts: action.payload,
            }
        },
    },
})

const {
    actions: alertsStateActions,
    reducer: alertsStateReducer,
} = alertsSlice

export { alertsStateActions, alertsStateReducer }
